/* =============================================================================
   7. GRID & CONTENT COMPONENTS
   ============================================================================= */
/**
 * Grid layout for cards and content blocks.
 */
.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem; /* unified gap value */
    align-items: stretch;
}

.grid-item {
    padding: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: white;
    border: 2px solid #ccc;
    transition: transform 0.3s ease, background-color 0.3s ease;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #f8d7da 30%, var(--primary) 70%);
}

.grid-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Icons */
.folder-icon {
    color: #ffc107 !important; /* A classic folder yellow */
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

.file-icon {
    color: #6c757d !important; /* A neutral file gray */
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

/* Statistics Section */
.chart-container,
.stats-legend {
    height: 300px;
    padding: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid #dee2e6;
}

.stats-legend {
    overflow-y: auto;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.legend-color {
    width: 16px;
    height: 16px;
    margin-right: 0.5rem;
    border-radius: 0.2rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .grid-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .grid-item {
        padding: 0.75rem;
    }

    .chart-container,
    .stats-legend {
        height: 250px;
    }
}