// _fonts.scss
/* =============================================================================
   FONT DEFINITIONS
   ============================================================================= */

/* Define custom Verdana fonts for consistent typography */
@font-face {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  src: url("../../../src/fonts/verdana-font-family/verdana.ttf")
    format("truetype");
}
@font-face {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 700;
  src: url("../../../src/fonts/verdana-font-family/verdana-bold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Verdana";
  font-style: italic;
  font-weight: 700;
  src: url("../../../src/fonts/verdana-font-family/verdana-bold-italic.ttf")
    format("truetype");
}
