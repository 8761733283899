/* =============================================================================
   6. SIDE NAVIGATION & RESPONSIVE MENU
   ============================================================================= */
/**
 * Sidebar navigation with a subtle desktop shadow.
 */
 .nav {
    min-height: calc(100vh - 70px);
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    box-shadow: 1px 1px 10px rgba(198, 189, 248, 0.6);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0;
    transition: all var(--transition-fast);
  }
  
  .navcontainer {
    min-height: calc(100vh - 70px);
    width: 200px;
    position: relative;
    overflow-y: auto;
    transition: all var(--transition-fast);
  }
  
  .navclose {
    width: 80px;
  }
  .navclose .nav-img {
    height: 35px;
    color: #626262;
  }
  
  /* Navigation Options */
  .nav-options {
    max-height: calc(100vh - 130px);
    display: flex;
    flex-direction: column;
    padding: var(--spacing-md);
    gap: var(--spacing-md);
    flex-grow: 1;
  }
  .nav-option {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: var(--spacing-sm);
    gap: var(--spacing-sm);
    transition: all 0.15s ease-in-out;
    border-left: 5px solid transparent;
  }
  .nav-option h3 {
    font-size: 1rem;
    word-break: break-word;
    transition: all var(--transition-slow);
  }
  
  /* Hide text when collapsed */
  .hidden h3,
  .navclose .nav-option h3 {
    display: none;
  }
  .nav-img {
    height: 20px;
  }
  
  /* Navigation Option Interaction */
  .option {
    background-color: var(--background-color);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border-radius: 0.3rem;
  }
  .option:hover {
    border-left: 5px solid var(--secondary);
    background-color: var(--primary);
    color: #ffffff;
  }
  
  /* Logout Button */
  .logout-button {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--primary);
    color: #ffffff;
    padding: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  .logout-button:hover {
    background-color: var(--secondary);
  }
  
  /* Mobile Navigation */
  @media screen and (max-width: 850px) {
    .navcontainer {
      width: 100vw;
      z-index: 999999;
      background: var(--background-color);
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.6s ease-in-out;
    }
    .nav {
      width: 100%;
      box-shadow: none;
    }
    .navcontainer.navclose {
      left: -100vw;
    }
    .searchbar {
      display: none;
    }
    .main {
      padding: var(--spacing-md);
    }
  }
